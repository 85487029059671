import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const SlideShow: React.FC = () => {

  const lastNews = useStaticQuery(query);

  function renderNewsData(lastNews) {
    return lastNews.map((currentNew,key) => 
      (
        <Carousel.Item key={key}>
        
        {currentNew.node.image && <Img fixed={currentNew.node.image.childImageSharp.fixed} alt={currentNew.title} className="d-block w-100 rounded"/>}
          <Carousel.Caption>
            <p>{currentNew.node.subtitle}</p>
          </Carousel.Caption>
        </Carousel.Item>
      )
    );
  }

  return (
    <div id="slideshow">
      <Carousel>
        { renderNewsData(lastNews.allStrapiArticles.edges) };
      </Carousel>
    </div>
  );
}

const query = graphql`
  query SlideQuery{
    allStrapiArticles(limit:5){
      edges{
        node{
          id
          title
          subtitle
          image {
            childImageSharp {
              fixed(height:400,quality:100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
export default SlideShow;